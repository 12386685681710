import React, { useState, useEffect } from "react";
import BillingTable from "./BillingTable.js";
import ColumnVisibilityPopover from "./ColumnVisibilityPopover.js";
import { useColumnVisibility } from "./useColumnVisibility.js";
import BillingDetailsRow from "./BillingDetailsRow.js";

const BillingWizard = ({ items, billingProfile, inboundShipment }) => {
  const [billingData, setBillingData] = useState({});
  const [showCosts, setShowCosts] = useState(() => {
    const storedShowCosts = localStorage.getItem("showCosts");
    return storedShowCosts !== null ? JSON.parse(storedShowCosts) : true;
  });

  useEffect(() => {
    localStorage.setItem("showCosts", JSON.stringify(showCosts));
  }, [showCosts]);

  const billingFields = [
    {
      name: "Prep",
      billingColumn: "prep_price",
      prep_instruction_column: "prep",
      item_quantity_column: "prep_quantity",
    },
    {
      name: "Polybag",
      billingColumn: "polybag_price",
      prep_instruction_column: "polybag",
      item_quantity_column: "poly_quantity",
    },
    {
      name: "Bubble Wrap",
      billingColumn: "bubble_wrap_price",
      prep_instruction_column: "bubble_wrap",
      item_quantity_column: "bubble_wrap_quantity",
    },
    {
      name: "Large Polybag",
      billingColumn: "large_polybag_price",
      prep_instruction_column: "large_polybag",
      item_quantity_column: "large_polybag_quantity",
    },
    {
      name: "Rubber Band",
      billingColumn: "rubber_band_price",
      prep_instruction_column: "rubber_band",
      item_quantity_column: "rubber_band_quantity",
    },
    {
      name: "Heavy Long",
      billingColumn: "heavy_long_price",
      prep_instruction_column: "heavy_long",
      item_quantity_column: "heavy_long_quantity",
    },
    {
      name: "Kitting",
      billingColumn: "kitting_price",
      prep_instruction_column: "kitting",
      item_quantity_column: "kitting_quantity",
    },
    {
      name: "Insert",
      billingColumn: "insert_price",
      prep_instruction_column: "insert",
      item_quantity_column: "insert_quantity",
    },
    {
      name: "Carton Label",
      billingColumn: "carton_label_price",
      prep_instruction_column: "carton_label",
      item_quantity_column: "carton_label_quantity",
    },
    {
      name: "Box Crafting",
      billingColumn: "box_crafting_price",
      prep_instruction_column: "box_crafting",
      item_quantity_column: "box_crafting_quantity",
    },
    {
      name: "Tape Sticker",
      billingColumn: "tape_sticker_price",
      prep_instruction_column: "tape_sticker",
      item_quantity_column: "tape_sticker_quantity",
    },
    {
      name: "Collar",
      billingColumn: "collar_price",
      prep_instruction_column: "collar",
      item_quantity_column: "collar_quantity",
    },
    {
      name: "Transparency Label",
      billingColumn: "transparency_label_price",
      prep_instruction_column: "transparency_label",
      item_quantity_column: "transparency_label_quantity",
    },
    {
      name: "Mavuno Kit",
      billingColumn: "mavuno_kit_price",
      prep_instruction_column: "mavuno_kit",
      item_quantity_column: "mavuno_kit_quantity",
    },
    {
      name: "Misc1",
      billingColumn: "misc1_price",
      prep_instruction_column: "misc1",
      item_quantity_column: "misc1_quantity",
    },
    {
      name: "Misc2",
      billingColumn: "misc2_price",
      prep_instruction_column: "misc2",
      item_quantity_column: "misc2_quantity",
    },
    {
      name: "Misc3",
      billingColumn: "misc3_price",
      prep_instruction_column: "misc3",
      item_quantity_column: "misc3_quantity",
    },
  ];

  const [visibleColumns, toggleColumnVisibility] =
    useColumnVisibility(billingFields);

  useEffect(() => {
    // Initialize billingData with values from the database
    const initialBillingData = {};

    items.forEach((item) => {
      initialBillingData[item.id] = {};

      billingFields.forEach((field) => {
        initialBillingData[item.id][field.billingColumn] =
          item[field.item_quantity_column] || 0;
      });
    });
    setBillingData(initialBillingData);
  }, [items]);

  const handleInputChange = (itemId, fieldType, value) => {
    setBillingData((prevData) => ({
      ...prevData,
      [itemId]: {
        ...prevData[itemId],
        [fieldType]: value,
      },
    }));
  };

  const handleShowCostsChange = () => {
    setShowCosts(!showCosts);
  };

  const handleResetBilling = () => {
    if (confirm("Are you sure you want to reset all billing data?")) {
      fetch(
        `/admin/inbound_shipments/${inboundShipment.public_id}/wizard/reset_billing`,
        {
          method: "POST",
          headers: {
            "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]')
              .content,
          },
        }
      )
        .then((response) => response.json())
        .then((data) => {
          if (data.success) {
            alert("Billing data has been reset.");
            window.location.reload(); // Reload the page to reflect the changes
          } else {
            alert("Failed to reset billing data.");
          }
        })
        .catch((error) => {
          console.error("Error:", error);
          alert("An error occurred while resetting billing data.");
        });
    }
  };

  return (
    <div className="billing-wizard">
      <h2>Billing Wizard</h2>
      <div className="mb-3 d-flex justify-content-between align-items-center">
        <label className="form-check-label">
          <input
            type="checkbox"
            className="form-check-input me-2"
            checked={showCosts}
            onChange={handleShowCostsChange}
          />
          Show Calculated Costs
        </label>
        <button className="btn btn-warning" onClick={handleResetBilling}>
          Reset Billing To Defaults
        </button>

        <ColumnVisibilityPopover
          columns={billingFields}
          visibleColumns={visibleColumns}
          toggleColumnVisibility={toggleColumnVisibility}
        />
      </div>
      <div style={{ overflowX: "auto", maxWidth: "100%" }}>
        <BillingTable
          items={items}
          billingFields={billingFields}
          billingProfile={billingProfile}
          billingData={billingData}
          onInputChange={handleInputChange}
          showCosts={showCosts}
          visibleColumns={visibleColumns}
        />
      </div>
      <div className="mt-3">
        <strong> {inboundShipment.shipment_id}</strong>
      </div>
      <BillingDetailsRow
        items={items}
        billingFields={billingFields}
        billingProfile={billingProfile}
        billingData={billingData}
      />
    </div>
  );
};

export default BillingWizard;
