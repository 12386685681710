// BillingTable.js
import React from "react";
import BillingRow from "./BillingRow.js";
import BillingTotalsRow from "./BillingTotalsRow.js";

const BillingTable = ({
  items,
  billingFields,
  billingProfile,
  billingData,
  onInputChange,
  showCosts,
  visibleColumns,
}) => {
  const visibleBillingFields = billingFields.filter(
    (field) => visibleColumns[field.name]
  );

  return (
    <div className="table-responsive">
      <table className="table table-striped table-bordered">
        <thead>
          <tr>
            <th
              style={{
                position: "sticky",
                left: 0,
                backgroundColor: "white",
                zIndex: 2,
                minWidth: "100px",
              }}
            >
              Sku
            </th>
            <th
              style={{
                position: "sticky",
                left: "100px",
                backgroundColor: "white",
                zIndex: 9999,
                minWidth: "200px",
              }}
            >
              Item
            </th>
            <th
              style={{
                position: "sticky",
                left: "300px",
                backgroundColor: "white",
                zIndex: 2,
                minWidth: "100px",
              }}
            >
              Quantity
            </th>
            {visibleBillingFields.flatMap((field) =>
              [
                <th key={field.name}>{field.name}</th>,
                showCosts && (
                  <th key={`${field.name}-cost`}>{field.name} Cost</th>
                ),
              ].filter(Boolean)
            )}
          </tr>
        </thead>
        <tbody>
          {items.map((item) => (
            <BillingRow
              key={item.id}
              item={item}
              billingFields={visibleBillingFields}
              billingProfile={billingProfile}
              billingData={billingData[item.id] || {}}
              onInputChange={onInputChange}
              showCosts={showCosts}
            />
          ))}
          <BillingTotalsRow
            items={items}
            billingFields={visibleBillingFields}
            billingProfile={billingProfile}
            billingData={billingData}
            showCosts={showCosts}
          />
        </tbody>
      </table>
    </div>
  );
};

export default BillingTable;
