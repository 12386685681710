import { useState, useEffect } from 'react';

export const useColumnVisibility = (columns) => {
  const [visibleColumns, setVisibleColumns] = useState(() => {
    const storedVisibility = localStorage.getItem('columnVisibility');
    return storedVisibility ? JSON.parse(storedVisibility) : 
      columns.reduce((acc, col) => ({ ...acc, [col.name]: true }), {});
  });

  useEffect(() => {
    localStorage.setItem('columnVisibility', JSON.stringify(visibleColumns));
  }, [visibleColumns]);

  const toggleColumnVisibility = (columnName) => {
    setVisibleColumns(prev => ({ ...prev, [columnName]: !prev[columnName] }));
  };

  return [visibleColumns, toggleColumnVisibility];
};