import React, { useState } from "react";

const BillingCell = ({
  item,
  field,
  billingProfile,
  billingData,
  onInputChange,
  showCosts,
}) => {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const fieldType = field.billingColumn;
  const prepInstructions = item.product.prep_instruction || {};
  const hasInstruction = prepInstructions[fieldType];
  const quantity =
    billingData[fieldType] !== undefined
      ? billingData[fieldType]
      : hasInstruction
      ? item.quantity_shipped
      : 0;

  const calculateCost = (fieldType, quantity) => {
    const price = billingProfile[fieldType];
    const priceFloat = parseFloat(price);
    return (priceFloat * (quantity || 0)).toFixed(2);
  };

  const handleBlur = (e) => {
    let value = parseInt(e.target.value, 10);
    if (isNaN(value)) {
      return;
    }
    setLoading(true);
    setSuccess(false);

    // Update the local state
    onInputChange(item.id, fieldType, value);

    // Send update to the server
    const data = {
      inbound_shipment_item: {
        [field.item_quantity_column]: value,
      },
    };
    updateQuantity(item, data);
    if (value !== 0) {
      updatePrepInstructions();
    }
  };

  const updatePrepInstructions = () => {
    const data = {
      prep_instruction: {
        [field.prep_instruction_column]: true,
      },
    };

    fetch(
      `/admin/products/${item.product.id}/prep_instructions/${item.product.prep_instruction.id}.json`,
      {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]')
            .content,
        },
        body: JSON.stringify(data),
      }
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          console.log("Prep instructions updated successfully");
        } else {
          console.error("Failed to update prep instructions");
        }
      })
      .catch((error) => console.error("Error:", error));
  };

  const updateQuantity = (item, data) => {
    fetch(`/admin/inbound_shipment_items/${item.public_id}`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]')
          .content,
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          console.log("Update successful");
          setLoading(false);
          setSuccess(true);
          setTimeout(() => setSuccess(false), 1000);
        } else {
          console.error("Update failed");
        }
      })
      .catch((error) => console.error("Error:", error))
      .finally(() => {
        setTimeout(() => setLoading(false), 1000);
      });
  };

  return (
    <React.Fragment>
      <td style={{ minWidth: '100px' }}>
        <input
          type="number"
          className={`form-control billing-input ${fieldType}-input`}
          value={quantity}
          onChange={(e) =>
            onInputChange(item.id, fieldType, parseInt(e.target.value, 10))
          }
          onBlur={handleBlur}
          min={0}
          data-item-id={item.id}
          data-field-type={fieldType}
          data-instruction={hasInstruction ? "true" : "false"}
          style={{
            border: loading
              ? "2px solid yellow"
              : success
              ? "2px solid green"
              : "",
            minWidth: '80px',
            width: '100%'
          }}
        />
      </td>
      {showCosts && (
        <td
          className={`${fieldType}-cost`}
          data-price={billingProfile[fieldType]}
          data-item-id={item.id}
          style={{ minWidth: '80px' }}
        >
          ${calculateCost(fieldType, quantity)}
        </td>
      )}
    </React.Fragment>
  );
};

export default BillingCell;
