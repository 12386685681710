import React from "react";

class ExportCarton extends React.PureComponent {
  state = {};

  render() {
    return (
      <React.Fragment>
        <a
          className="btn btn-secondary btn-sm mx-2"
          href={`/admin/inbound_shipments/${this.props.shipment_public_id}/cartons/csv_export`}
        >
          Export Carton CSV
        </a>
      </React.Fragment>
    );
  }
}

export default ExportCarton;
