import React from "react";
import { fetcher } from "../utlities/adapter";

class BulkExpirationDateForm extends React.Component {
  state = {
    submitting: false,
    expirationDate: "",
  };

  url = () => {
    return `/admin/inbound_shipment_items/bulk_update`;
  };

  handleSubmit = async (event) => {
    if (this.state.expirationDate === "") return;

    this.setState({ submitting: true });

    let url = this.url();

    const data = {
      inbound_shipment_item_bulk_update: {
        ids: this.props.inbound_shipment_item_ids,
        expiration_date: this.state.expirationDate,
      },
    };

    let res = await fetcher(url, "POST", data);

    this.setState({ submitting: false });
    if (res.success) {
      window.topAlert(res.message, "success");
    } else {
      window.topAlert(res.message, "danger");
    }
    this.props.fetchItemsList();
  };
  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  render() {
    return (
      <React.Fragment>
        <div className="d-flex">
          <input
            autoComplete="off"
            type="date"
            className="form-control w-auto d-inline"
            value={this.state.expirationDate}
            onChange={this.handleChange}
            name="expirationDate"
          />
          <button
            type="submit"
            className="btn btn-icon btn-success btn-sm"
            onClick={this.handleSubmit}
          >
            <i
              className={
                this.state.submitting
                  ? "fas fa-spin fa-spinner"
                  : "fas fa-check"
              }
            ></i>
          </button>
        </div>
      </React.Fragment>
    );
  }
}

export default BulkExpirationDateForm;
