import React from "react";
import ItemRow from "./ItemRow";
import BulkExpirationDateForm from "../BulkExpirationDateForm";
import { fetcher } from "../../utlities/adapter";

class ItemsList extends React.Component {
  state = { items: [] };

  componentDidMount = () => {
    this.fetchItemsList();
    this.setListener();
  };

  setListener = () => {
    if (document.body.getAttribute("refreshItemsListerer")) {
      return;
    } else {
      document.body.setAttribute("refreshItemsListerer", true);
      window.addEventListener("refreshItems", this.fetchItemsList);
    }
  };

  fetchItemsList = async () => {
    let ransack = `q[inbound_shipment_public_id_eq]=${this.props.shipment_public_id}`;

    let url = `/admin/inbound_shipment_items?${ransack}`;

    let res = await fetcher(url, "GET");
    this.setState({ items: res.inbound_shipment_items });
  };

  render() {
    return (
      <React.Fragment>
        <div className="table-responsive">
          <table className="table table-hover table-borderless">
            <thead>
              <tr>
                <th>Sku</th>
                <th>Name</th>
                <th>Condition</th>
                <th>Prep</th>
                <th>Raw Prep</th>
                <th>Amount</th>
                <th>Per case</th>
                <th>Remaining</th>
                <th>Item label</th>
                <th>
                  Packing doc
                  <a
                    target="_blank"
                    href={`/admin/inbound_shipments/${this.props.shipment_public_id}/packing_doc`}
                  >
                    <button className="btn">
                      <i className="fas fa-print"></i>
                    </button>
                  </a>
                </th>
                <th>
                  Expiration Date
                  <BulkExpirationDateForm
                    fetchItemsList={this.fetchItemsList}
                    inbound_shipment_item_ids={this.state.items.map(
                      (item) => item.id
                    )}
                  />
                </th>
              </tr>
            </thead>
            <tbody>
              {this.state.items.map((item) => (
                <ItemRow item={item} key={item.id} />
              ))}
            </tbody>
          </table>

          {this.state.items.map((item) => (
            <div
              key={item.id}
              className="modal fade"
              id={`rawPrepModal-${item.id}`}
              tabIndex="-1"
              role="dialog"
              aria-hidden="true"
            >
              <div className="modal-dialog" role="document">
                <div className="modal-content">
                  <div className="modal-body">
                    <pre className="json">
                      {JSON.stringify(item.prep_guidance, undefined, 2)}
                    </pre>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </React.Fragment>
    );
  }
}

export default ItemsList;
