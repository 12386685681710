import React from "react";
import { fetcher } from "../utlities/adapter";

class ExpirationDateForm extends React.Component {
  state = {
    submitting: false,
    expirationDate: this.props.expiration_date,
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.expiration_date !== this.state.expirationDate) {
      this.setState({ expirationDate: nextProps.expiration_date });
    }
  }

  url = () => {
    return `/${this.props.account_public_id}/inbound_shipment_items/${this.props.item_public_id}`;
  };

  handleSubmit = async (event) => {
    this.setState({ submitting: true });

    let url = this.url();

    const data = {
      inbound_shipment_item: {
        expiration_date: this.state.expirationDate,
      },
    };

    let res = await fetcher(url, "PATCH", data);

    this.setState({ submitting: false });
    if (res.success) {
      window.topAlert(res.message, "success");
    } else {
      window.topAlert(res.message, "danger");
    }
  };
  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  render() {
    return (
      <React.Fragment>
        <div className="d-flex">
          <input
            placeholder="YYYY-MM-DD"
            autoComplete="off"
            type="date"
            className="form-control w-auto d-inline"
            value={this.state.expirationDate}
            onChange={this.handleChange}
            name="expirationDate"
          />
          <button
            type="submit"
            className="btn btn-icon btn-success btn-sm"
            onClick={this.handleSubmit}
          >
            <i
              className={
                this.state.submitting
                  ? "fas fa-spin fa-spinner"
                  : "fas fa-check"
              }
            ></i>
          </button>
        </div>
      </React.Fragment>
    );
  }
}

export default ExpirationDateForm;
