import React from "react";
import ExpirationDateForm from "../ExpirationDateForm";

class ItemsList extends React.Component {
  render() {
    const { item } = this.props;
    return (
      <React.Fragment>
        <tr>
          <td className="fs-14">{item.seller_sku}</td>
          <td style={{ width: "30%" }}>{item.product && item.product.title}</td>
          <td>{item.product && item.product.condition_type}</td>
          <td>{item.prep_instructions}</td>
          <td>
            <button
              type="button"
              className="btn btn-link"
              data-toggle="modal"
              data-target={`#rawPrepModal-${item.id}`}
            >
              View
            </button>
          </td>
          <td>{item.quantity_shipped}</td>
          <td>{item.quantity_in_case}</td>
          <td>{item.quantity_shipped - item.allotted_quantity}</td>
          <td>
            <a
              href={`/admin/inbound_shipment_items/${item.public_id}/item_labels`}
            >
              <button className="btn">
                <i className="fas fa-print"></i>
              </button>
            </a>
          </td>
          <td>
            <a
              target="_blank"
              href={`/admin/inbound_shipment_items/${item.public_id}/packing_doc`}
            >
              <button className="btn">
                <i className="fas fa-print"></i>
              </button>
            </a>
          </td>
          <td>
            <ExpirationDateForm
              account_public_id={"admin"}
              item_public_id={item.public_id}
              item={item}
              expiration_date={item.expiration_date || ""}
            />
          </td>
        </tr>
      </React.Fragment>
    );
  }
}

export default ItemsList;
