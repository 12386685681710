import React from "react";
import { fileUploader } from "../../utlities/adapter";

class ImportCarton extends React.PureComponent {
  state = { file: null, errors: null, loading: false };

  handleFileChange = (event) => {
    this.setState({ file: event.target.files[0] });
  };

  import = async (shouldDeleteExistingCartons) => {
    this.setState({
      errors: null,
      loading: true,
      deleteLoading: shouldDeleteExistingCartons,
    });
    let url = `/admin/inbound_shipments/${this.props.shipment_public_id}/cartons/csv_import`;

    const formData = new FormData();

    formData.append("file", this.state.file);
    if (shouldDeleteExistingCartons) {
      formData.append("delete_existsing_cartons", true);
    } else {
      formData.append("delete_existsing_cartons", false);
    }

    let res = await fileUploader(url, "POST", formData);
    this.setState({ loading: false });
    if (res.success) {
      window.storeAlert(res.message, "success");
      window.location.reload();
    } else {
      this.setState({ errors: res.errors });
    }
  };

  render() {
    return (
      <React.Fragment>
        <button
          type="button"
          className="btn btn-secondary btn-sm mx-2"
          data-toggle="modal"
          data-target="#importCartonModal"
        >
          Import CSV
        </button>
        <div
          className="modal fade"
          id="importCartonModal"
          tabIndex="-1"
          role="dialog"
          aria-hidden="true"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Import CSV</h5>
                <button
                  type="button"
                  className="btn close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">
                    <i className="fa-solid fa-xmark"></i>
                  </span>
                </button>
              </div>
              <div className="modal-body">
                <p>File needs to be in CSV format</p>
                <p>Expiration Date Format (yyyy-dd-mm)</p>
                <a href="/import_carton_template_new.xlsx">Download Template</a>
                <input
                  type="file"
                  name="file"
                  id=""
                  className="form-control mt-4"
                  onChange={this.handleFileChange}
                />

                {this.state.errors && (
                  <div
                    id="fulfill-error"
                    className="alert alert-danger mt-4"
                    role="alert"
                  >
                    <ul>
                      {this.state.errors.map((error, index) => {
                        return <li key={index}>{error}</li>;
                      })}
                    </ul>
                  </div>
                )}
              </div>

              <div className="modal-footer">
                <button
                  className={
                    "btn btn-primary" +
                    (this.state.loading && this.state.deleteLoading
                      ? " loading"
                      : "")
                  }
                  onClick={() => this.import(true)}
                >
                  Import (Delete Existing)
                </button>
                <button
                  className={
                    "btn btn-primary" +
                    (this.state.loading && !this.state.deleteLoading
                      ? " loading"
                      : "")
                  }
                  onClick={() => this.import(false)}
                >
                  Import (Add)
                </button>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default ImportCarton;
