import React from "react";
import PropTypes from "prop-types";
import SkuInCartonList from "./SkuInCartonList";
import { fetcher } from "../../utlities/adapter";

class CartonBody extends React.PureComponent {
  constructor(props) {
    super(props);
    this.selectForm = React.createRef();
  }

  state = {
    submitting: false,
  };

  handleChange = () => {
    this.setState({ [event.target.name]: event.target.value });
  };

  itemsNotInCarton = () => {
    const allItems = this.props.items;
    const includedItems = this.props.carton.carton_contents.map(
      (cartonContent) => cartonContent.inbound_shipment_item_id
    );

    const remainingItems = allItems.filter((item) => {
      return !includedItems.includes(item.id);
    });

    return remainingItems;
  };

  renderSelectMenu = () => {
    const items = this.itemsNotInCarton();

    return items.map((item) => (
      <option key={item.id} value={item.id}>
        {item.seller_sku}
      </option>
    ));
  };

  handleSelectedSkuButton = async (event) => {
    this.setState({ submitting: true });
    let url = `/admin/inbound_shipments/${this.props.shipment_public_id}/cartons/${this.props.carton.id}/carton_contents`;
    url += "?react=true";
    const body = {
      carton_content: {
        inbound_shipment_item_id: this.selectForm.current.value,
        carton_id: this.props.carton.id,
        quantity: 0,
      },
    };

    let res = await fetcher(url, "POST", body);
    this.props.fetchCarton(this.props.carton.id);
    this.setState({ submitting: false });
    // this.props.fetchCartonList();
  };

  render() {
    return (
      <React.Fragment>
        <div className="card-body">
          <div className="skus-in-carton table-responsive">
            <table className="table">
              <thead>
                <tr className="fs-14">
                  <td>sku</td>
                  <td>in box</td>
                  <td>remaining</td>
                  <td>Remove</td>
                </tr>
              </thead>
              <tbody>
                {this.props.carton.carton_contents.map((cartonContent) => (
                  <SkuInCartonList
                    // allotedQuantity={
                    //   this.props.allotedItems[
                    //     cartonContent.inbound_shipment_item_id
                    //   ]
                    // }
                    outdated={this.props.carton.outdated}
                    key={cartonContent.id}
                    cartonContent={cartonContent}
                    shipment_public_id={this.props.shipment_public_id}
                    fetchCartonList={this.props.fetchCartonList}
                    fetchCarton={this.props.fetchCarton}
                  />
                ))}
              </tbody>
            </table>
          </div>
          {/* select form */}
          {this.itemsNotInCarton().length > 0 && (
            <div className="d-flex">
              <select
                ref={this.selectForm}
                name="selectedSku"
                className="form-control select2 form-select form-select-sm d-inline w-75"
              >
                {this.renderSelectMenu()}
              </select>
              <button
                className="btn btn-outline-primary btn-sm ms-1 d-inline"
                onClick={this.handleSelectedSkuButton}
              >
                Select Sku
                {this.state.submitting && (
                  <i className={"text-14 fa fa-spin fa-spinner"}></i>
                )}
              </button>
            </div>
          )}
        </div>
      </React.Fragment>
    );
  }
}

export default CartonBody;
