import React from "react";
import PropTypes from "prop-types";
import CartonHeader from "./CartonHeader";
import CartonBody from "./CartonBody";
import CreateCarton from "./CreateCarton";
import SyncThreePlCartons from "./SyncThreePlCartons";
import ImportCarton from "./ImportCarton";
import ExportCarton from "./ExportCarton";

import { fetcher } from "../../utlities/adapter";

class CartonList extends React.Component {
  state = {
    cartons: [],
    allotedItems: {},
  };

  componentDidMount = () => {
    this.fetchCartonList();
  };

  fetchCartonList = async () => {
    let url = `/admin/inbound_shipments/${this.props.shipment_public_id}/cartons`;
    let res = await fetcher(url, "GET");

    this.setState({ cartons: res.cartons });
    this.refreshTransportErrors();
  };

  refreshTransportErrors = () => {
    event = new CustomEvent("refreshTransportErrors", {});
    window.dispatchEvent(event);
  };

  fetchCarton = async (cartonId) => {
    let url = `/admin/inbound_shipments/${this.props.shipment_public_id}/cartons/${cartonId}`;
    let res = await fetcher(url, "GET");
    let updatedCarton = res.carton;

    const newCartonList = this.state.cartons.map((carton) => {
      if (carton.id === updatedCarton.id) {
        return updatedCarton;
      } else {
        return carton.outdated ? carton : { ...carton, outdated: true };
      }
    });

    this.setState({ cartons: newCartonList });
  };

  addToCartonList = (newCarton) => {
    const cartonList = this.state.cartons.map((e) => {
      return e.outdated ? e : { ...e, outdated: true };
    });

    this.setState({ cartons: [...cartonList, newCarton] });
    this.refreshItems();
  };

  removeCartonFromList = (cartonId) => {
    let cartonList = this.state.cartons.filter(
      (carton) => carton.id !== cartonId
    );

    cartonList = cartonList.map((e) => {
      return e.outdated ? e : { ...e, outdated: true };
    });

    this.setState({ cartons: cartonList });
    this.refreshItems();
  };

  refreshItems = () => {
    event = new CustomEvent("refreshItems", {});
    window.dispatchEvent(event);
  };

  render() {
    const { cartons } = this.state;
    return (
      <React.Fragment>
        <div className="mb-4">
          <CreateCarton
            shipment_public_id={this.props.shipment_public_id}
            fetchCartonList={this.fetchCartonList}
            addToCartonList={this.addToCartonList}
          />
          <SyncThreePlCartons
            shipment_public_id={this.props.shipment_public_id}
          />
          <ImportCarton shipment_public_id={this.props.shipment_public_id} />
          <ExportCarton shipment_public_id={this.props.shipment_public_id} />
        </div>
        {cartons.map((carton) => (
          <div className="col-md-12 col-lg-6 carton pb-3" key={carton.id}>
            <div className="card border p-0 box-card h-100">
              <CartonHeader
                removeCartonFromList={this.removeCartonFromList}
                carton={carton}
                shipment_public_id={this.props.shipment_public_id}
                fetchCartonList={this.fetchCartonList}
                addToCartonList={this.addToCartonList}
              />
              <CartonBody
                // allotedItems={this.state.allotedItems}
                carton={carton}
                shipment_public_id={this.props.shipment_public_id}
                fetchCartonList={this.fetchCartonList}
                items={this.props.items}
                fetchCarton={this.fetchCarton}
              />
            </div>
          </div>
        ))}
      </React.Fragment>
    );
  }
}

export default CartonList;
