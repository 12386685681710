import React from "react";
import { fetcher } from "../../utlities/adapter";

class SyncThreePlCartons extends React.PureComponent {
  state = { submitting: false };

  createCarton = async () => {
    this.setState({ submitting: true });
    let url = `/admin/inbound_shipments/${this.props.shipment_public_id}/sync_three_pl_cartons.json`;
    let res = await fetcher(url, "POST", {});
    this.setState({ submitting: false });
    if (res.success) {
      window.topAlert(res.message, "success");
    } else {
      window.topAlert(res.message, "danger");
    }
    window.location.reload();
  };

  render() {
    return (
      <React.Fragment>
        <button
          className="btn btn-secondary btn-sm"
          onClick={this.createCarton}
        >
          Sync Cartons from 3PL
          {this.state.submitting && (
            <i className={"text-14 fa fa-spin fa-spinner"}></i>
          )}
        </button>
      </React.Fragment>
    );
  }
}

export default SyncThreePlCartons;
