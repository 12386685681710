import React from 'react';

const BillingTotalsRow = ({ items, billingFields, billingProfile, billingData, showCosts }) => {
  const totals = items.reduce((acc, item) => {
    acc.quantity += item.quantity_shipped;
    billingFields.forEach(field => {
      const fieldType = field.billingColumn;
      const quantity = billingData[item.id]?.[fieldType] || 0;
      acc[fieldType] = (acc[fieldType] || 0) + quantity;
      if (showCosts) {
        const cost = parseFloat(billingProfile[fieldType]) * quantity;
        acc[`${fieldType}_cost`] = (acc[`${fieldType}_cost`] || 0) + cost;
      }
    });
    return acc;
  }, { quantity: 0 });

  return (
    <tr className="table-active">
      <td colSpan={2}><strong>Totals</strong></td>
      <td><strong>{totals.quantity}</strong></td>
      {billingFields.map(field => (
        <React.Fragment key={field.billingColumn}>
          <td><strong>{totals[field.billingColumn] || 0}</strong></td>
          {showCosts && (
            <td>
              <strong>
                ${(totals[`${field.billingColumn}_cost`] || 0).toFixed(2)}
              </strong>
            </td>
          )}
        </React.Fragment>
      ))}
    </tr>
  );
};

export default BillingTotalsRow;