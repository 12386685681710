import React from "react";
import PropTypes from "prop-types";
import { fetcher } from "../../utlities/adapter";

class SkuInCartonList extends React.PureComponent {
  state = {
    quantity: this.props.cartonContent.quantity,
    submitting: false,
  };

  url = () => {
    const { carton_id, id } = this.props.cartonContent;

    let url = `/admin/inbound_shipments/${this.props.shipment_public_id}/cartons/${carton_id}/carton_contents/${id}`;
    url += "?react=true";
    return url;
  };

  handleUpdateCartonQuantity = async () => {
    this.setState({ submitting: true });
    const body = {
      carton_content: {
        quantity: this.state.quantity,
      },
    };

    let res = await fetcher(this.url(), "PATCH", body);

    if (res.success) {
      window.topAlert(res.message, "success");
    } else {
      window.topAlert(res.message, "danger");
    }
    this.setState({ submitting: false });
    this.props.fetchCarton(this.props.cartonContent.carton_id);
    this.refreshItems();
  };

  refreshItems = () => {
    event = new CustomEvent("refreshItems", {});
    window.dispatchEvent(event);
  };

  handleDeleteCartonContent = async () => {
    let res = await fetcher(this.url(), "DELETE");
    if (res.success) {
      window.topAlert(res.message, "success");
    } else {
      window.topAlert(res.message, "danger");
    }
    this.props.fetchCarton(this.props.cartonContent.carton_id);
    this.refreshItems();
  };

  handleRefreshBtn = () => {
    this.props.fetchCartonList();
  };

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    const { seller_sku, quantity_shipped, allotted_quantity } =
      this.props.cartonContent.inbound_shipment_item;
    const { quantity } = this.state;

    return (
      <React.Fragment>
        <tr>
          <td className="fs-14">{seller_sku}</td>
          <td>
            <div className="d-flex">
              <input
                className="w-50"
                type="text"
                value={quantity}
                name="quantity"
                onChange={this.handleChange}
              />
              <button
                type="submit"
                className="btn btn-icon btn-success btn-sm mx-2"
                onClick={this.handleUpdateCartonQuantity}
              >
                <i
                  className={
                    "text-14 fa " +
                    (this.state.submitting ? "fa-spin fa-spinner" : "fa-check")
                  }
                ></i>
              </button>
            </div>
          </td>
          {this.props.outdated ? (
            <td>
              <button className="btn" onClick={this.handleRefreshBtn}>
                <i className="fas fa-refresh"></i>
              </button>
            </td>
          ) : (
            <td>{quantity_shipped - allotted_quantity}</td>
          )}
          <td>
            <button className="btn" onClick={this.handleDeleteCartonContent}>
              <i className="fas fa-trash-alt"></i>
            </button>
          </td>
        </tr>
      </React.Fragment>
    );
  }
}

export default SkuInCartonList;
