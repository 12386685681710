import React from "react";
import { fetcher } from "../utlities/adapter";

class Input extends React.Component {
  state = {
    value: this.props.value || "",
    initialValue: this.props.value || "",
    submitting: false,
    error: false,
    success: false,
  };

  onChangeTimeout;

  handleChange = (event) => {
    this.setState({ value: event.target.value }, () => {
      if (this.props.inputType === "select") {
        this.submit();
      } else {
        this.setChangeTimeout();
      }
    });
  };

  setChangeTimeout = () => {
    clearTimeout(this.onChangeTimeout);

    this.onChangeTimeout = setTimeout(() => {
      this.submit();
    }, 1000);
  };

  handleBlur = () => {
    this.submit();
  };

  onSuccessCallbacks = () => {
    event = new CustomEvent("refreshTransportErrors", {});
    window.dispatchEvent(event);
  };

  submit = async () => {
    if (this.state.initialValue === this.state.value) return;
    this.setState({ submitting: true });

    let url = this.props.url + ".json";

    const data = {
      [this.props.paramName]: {
        [this.props.fieldName]: this.state.value,
      },
    };

    let res = await fetcher(url, "PATCH", data);

    this.setState({ submitting: false });

    if (res.success) {
      this.setState({
        initialValue: this.state.value,
        success: true,
        error: false,
      });
      this.onSuccessCallbacks();
    } else {
      this.setState({ error: true, success: false });
    }
  };

  iconClass() {
    if (this.state.submitting) {
      return "fas fa-spinner fa-spin";
    } else if (this.state.success) {
      return "fas fa-check text-success";
    } else {
      return "";
    }
  }

  renderInput() {
    const { fieldName, inputType, paramName, selectOptions, placeholder, autoFocus } =
      this.props;

    const sharedAttrs = {
      className: `form-control ${this.state.error && "is-invalid"}`,
      onChange: this.handleChange,
      name: `${paramName}[${fieldName}]`,
      autoFocus: autoFocus || false,
    };

    if (inputType === "select") {
      return (
        <select
          {...sharedAttrs}
          dangerouslySetInnerHTML={{ __html: selectOptions }}
        ></select>
      );
    } else if (inputType === "date") {
      return <input {...sharedAttrs} type="date" value={this.state.value} />;
    } else {
      return (
        <input
          {...sharedAttrs}
          type="text"
          placeholder={placeholder || fieldName}
          value={this.state.value}
          onBlur={this.handleBlur}
        />
      );
    }
  }

  render() {
    const { fieldName, inputType, paramName } = this.props;

    return (
      <React.Fragment>
        <div className="input-group mb-1">
          {this.renderInput()}
          <div className="input-group-append">
            <span className="btn disabled">
              <i className={this.iconClass()}></i>
            </span>
          </div>
          <span className="invalid-feedback">Error autosaving field</span>
        </div>
      </React.Fragment>
    );
  }
}

export default Input;
