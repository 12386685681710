import React from "react";
import PropTypes from "prop-types";
import { fetcher } from "../../utlities/adapter";

class CartonHeader extends React.PureComponent {
  state = {
    weight: this.props.carton.weight || "",
    width: this.props.carton.width || "",
    length: this.props.carton.length || "",
    height: this.props.carton.height || "",
    name: this.props.carton.name || "",
    submitting: false,
  };

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleDimensionsForm = async (event) => {
    this.setState({ submitting: true });

    let url = `/admin/inbound_shipments/${this.props.shipment_public_id}/cartons/${this.props.carton.id}`;
    url += "?react=true";

    const data = {
      carton: {
        weight: this.state.weight,
        width: this.state.width,
        length: this.state.length,
        height: this.state.height,
      },
    };
    let res = await fetcher(url, "PATCH", data);
    if (res.success) {
      window.topAlert(res.message, "success");
    } else {
      window.topAlert(res.message, "danger");
    }
    this.setState({ submitting: false });
  };

  handleDuplicateForm = async (event) => {
    let url = `/admin/inbound_shipments/${this.props.shipment_public_id}/cartons/${this.props.carton.id}/duplicate`;
    url += "?react=true";
    let res = await fetcher(url, "POST", {});

    if (res.success) {
      this.props.addToCartonList(res.carton);
      window.topAlert(res.message, "success");
    } else {
      window.topAlert(res.message, "danger");
    }
  };

  handleDeleteCartonForm = async (event) => {
    let url = `/admin/inbound_shipments/${this.props.shipment_public_id}/cartons/${this.props.carton.id}`;
    url += "?react=true";
    let res = await fetcher(url, "DELETE", {});

    this.props.removeCartonFromList(res.carton.id);
    if (res.success) {
      window.topAlert(res.message, "success");
    } else {
      window.topAlert(res.message, "danger");
    }
  };

  labelUrl = () => {
    let label_type = "label_type=PackageLabel_Thermal";
    let url = `/admin/inbound_shipments/${this.props.shipment_public_id}/cartons/${this.props.carton.id}/label?${label_type}`;
    return url;
  };

  render() {
    const { width, length, height, weight, name } = this.state;
    return (
      <React.Fragment>
        <div className="card-header d-block">
          <div>
            <h4 className="card-title d-inline">
              {this.props.carton.name} | {this.props.carton.id}
            </h4>
            <div className="card-options d-inline float-end">
              <a href={this.labelUrl()}>
                <button className="btn" onClick={this.handlePrintLabel}>
                  <i className="fas fa-print"></i>
                </button>
              </a>
              <button className="btn" onClick={this.handleDuplicateForm}>
                <i className="fas fa-copy"></i>
              </button>
              <button className="btn" onClick={this.handleDeleteCartonForm}>
                <i className="fas fa-trash-alt"></i>
              </button>
            </div>
          </div>
          <div className="row mt-2 align-items-end">
            <div className="col">
              <div className="form-group mb-0">
                <label className="form-label text-14 mb-1">Weight</label>
                <input
                  name="weight"
                  value={weight}
                  onChange={this.handleChange}
                  type="text"
                  className="form-control form-control-sm"
                  placeholder="pounds"
                />
              </div>
            </div>
            <div className="col">
              <div className="form-group mb-0">
                <label className="form-label text-14 mb-1">Width</label>
                <input
                  name="width"
                  onChange={this.handleChange}
                  value={width}
                  type="text"
                  className="form-control form-control-sm"
                  placeholder="in"
                />
              </div>
            </div>
            <div className="col">
              <div className="form-group mb-0">
                <label className="form-label text-14 mb-1">Length</label>
                <input
                  name="length"
                  onChange={this.handleChange}
                  value={length}
                  type="text"
                  className="form-control form-control-sm"
                  placeholder="in"
                />
              </div>
            </div>
            <div className="col">
              <div className="form-group mb-0">
                <label className="form-label text-14 mb-1">Height</label>
                <input
                  name="height"
                  onChange={this.handleChange}
                  value={height}
                  type="text"
                  className="form-control form-control-sm"
                  placeholder="in"
                />
              </div>
            </div>
            <div className="col">
              <button
                onClick={this.handleDimensionsForm}
                className="btn btn-icon btn-success btn-sm"
              >
                <i
                  className={
                    "text-14 fa " +
                    (this.state.submitting ? "fa-spin fa-spinner" : "fa-check")
                  }
                ></i>
              </button>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default CartonHeader;
