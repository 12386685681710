import React from "react";
import { fetcher } from "../utlities/adapter";

class RefreshShipmentForm extends React.Component {
  state = {
    submitting: false,
  };

  url = () => {
    return `/${this.props.account_public_id}/inbound_shipments/${this.props.shipment_public_id}/refresh`;
  };

  handleSubmit = async (event) => {
    this.setState({ submitting: true });

    let url = this.url();

    const data = {};

    let res = await fetcher(url, "POST", data);

    this.setState({ submitting: false });
    if (res.success) {
      window.storeAlert(res.message, "success");
    } else {
      window.storeAlert(res.message, "danger");
    }
    window.location.reload()
  };
  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  render() {
    return (
      <React.Fragment>
        <button
          type="submit"
          className={"btn btn-primary " + (this.state.submitting ? "disabled loading" : "")}
          onClick={this.handleSubmit}
        >
          Sync from API
        </button>
      </React.Fragment>
    );
  }
}

export default RefreshShipmentForm;
