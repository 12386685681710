import React from "react";
import { fetcher } from "../../utlities/adapter";

class CreateCarton extends React.PureComponent {
  state = { submitting: false };

  createCarton = async () => {
    this.setState({ submitting: true });
    let url = `/admin/inbound_shipments/${this.props.shipment_public_id}/cartons`;
    url += "?react=true";
    const data = {
      carton: { quantity: 0 },
    };

    let res = await fetcher(url, "POST", data);

    if (res.success) {
      this.props.addToCartonList(res.carton);
      window.topAlert(res.message, "success");
    } else {
      window.topAlert(res.message, "danger");
    }


    this.setState({ submitting: false });
  };

  render() {
    return (
      <React.Fragment>
        <button
          className="btn btn-secondary btn-sm mx-2"
          onClick={this.createCarton}
        >
          Create Carton
          {this.state.submitting && (
            <i className={"text-14 fa fa-spin fa-spinner"}></i>
          )}
        </button>
      </React.Fragment>
    );
  }
}

export default CreateCarton;
