import React, { useState, useRef, useEffect } from 'react';

const ColumnVisibilityPopover = ({ columns, visibleColumns, toggleColumnVisibility }) => {
  const [showPopover, setShowPopover] = useState(false);
  const popoverRef = useRef(null);
  const buttonRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popoverRef.current && !popoverRef.current.contains(event.target) && 
          buttonRef.current && !buttonRef.current.contains(event.target)) {
        setShowPopover(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="position-relative">
      <button 
        ref={buttonRef}
        onClick={() => setShowPopover(!showPopover)} 
        className="btn btn-secondary"
      >
        Column Visibility
      </button>

      {showPopover && (
        <div 
          ref={popoverRef}
          className="position-absolute start-0 mt-2 p-3 bg-white border rounded shadow"
          style={{ zIndex: 1000, minWidth: '200px' }}
        >
          <h6 className="mb-3">Toggle Columns</h6>
          {columns.map(column => (
            <div key={column.name} className="form-check">
              <input
                type="checkbox"
                className="form-check-input"
                id={`visibility-${column.name}`}
                checked={visibleColumns[column.name]}
                onChange={() => toggleColumnVisibility(column.name)}
              />
              <label className="form-check-label" htmlFor={`visibility-${column.name}`}>
                {column.name}
              </label>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default ColumnVisibilityPopover;