import React from "react";
import ReactDOM from "react-dom";

const Tooltip = ({ content, currentRect, position = "top" }) => {
  const positionMap = {
    top: { top: currentRect.top, left: currentRect.left },
    right: { top: currentRect.top, left: currentRect.right },
    bottom: { top: currentRect.bottom, left: currentRect.left },
    left: { top: currentRect.top, left: currentRect.left - currentRect.width },
  };

  return ReactDOM.createPortal(
    <div
      style={{
        position: "fixed",
        backgroundColor: "rgba(0, 0, 0, 0.8)",
        color: "white",
        padding: "10px",
        borderRadius: "4px",
        fontSize: "14px",
        zIndex: 9999999,
        minWidth: "200px",
        maxWidth: "300px",
        pointerEvents: "none",
        opacity: 1,
        display: "block",
        boxShadow: "0 2px 5px rgba(0,0,0,0.2)",
        ...positionMap[position],
      }}
    >
      {content}
    </div>,
    document.body
  );
};

export default Tooltip;
