import React from "react";

const BillingDetailsRow = ({
  items,
  billingFields,
  billingProfile,
  billingData,
}) => {
  const details = billingFields.reduce((acc, field) => {
    const fieldType = field.billingColumn;
    const quantity = items.reduce(
      (sum, item) => sum + (billingData[item.id]?.[fieldType] || 0),
      0
    );
    const cost = parseFloat(billingProfile[fieldType]);
    const total = cost * quantity;
    acc[fieldType] = {
      cost,
      quantity,
      total,
    };

    return acc;
  }, {});

  return (
    <div className="w-25 mt-4">
      <table className="table table-sm">
        <tbody className="billing-details">
          <tr>
            <td className="bg-light">
              <strong>Handling</strong>
            </td>
            <td>
              <strong>Cost</strong>
            </td>
            <td>
              <strong>Quantity</strong>
            </td>
            <td>
              <strong>Total</strong>
            </td>
          </tr>
          {billingFields.map((field) => (
            <tr key={field.billingColumn}>
              <td className="bg-light">{field.name}</td>
              <td>{details[field.billingColumn].cost.toFixed(2)}</td>
              <td>{details[field.billingColumn].quantity}</td>
              <td>{details[field.billingColumn].total.toFixed(2)}</td>
            </tr>
          ))}
          <tr>
            <td className="bg-light">
              <strong>Grand Total</strong>
            </td>
            <td>
              <strong>
                {Object.values(details)
                  .reduce((sum, detail) => sum + detail.total, 0)
                  .toFixed(2)}
              </strong>
            </td>
            <td>
              <strong>
                {Object.values(details).reduce((sum, detail) => sum + detail.quantity, 0)}
              </strong>
            </td>
            <td>
              <strong>
                {Object.values(details).reduce((sum, detail) => sum + detail.total, 0).toFixed(2)}
              </strong>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default BillingDetailsRow;
