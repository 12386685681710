// BillingRow.js
import React, { useState, useRef, useEffect } from "react";
import BillingCell from "./BillingCell";
import Tooltip from "./Tooltip";

const BillingRow = ({
  item,
  billingFields,
  billingProfile,
  billingData,
  onInputChange,
  showCosts,
}) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const cellRef = useRef(null);

  const prepInstructions = item.product.prep_instruction || {};
  const prepInstructionsTable = Object.entries(prepInstructions).map(
    ([key, value]) => {
      return (
        <li key={key} style={{ display: "flex", justifyContent: "space-between" }}>
          <span>{key}</span>
          <span>{value ? "Yes" : "No"}</span>
        </li>
      );
    }
  );

  return (
    <tr>
      <td
        style={{
          position: "sticky",
          left: 0,
          backgroundColor: "white",
          zIndex: 1,
          minWidth: "100px",
        }}
      >
        <a href={`/admin/products/${item.product.id}`} target="_blank">
          {item.product.sku}
        </a>
      </td>
      <td
        ref={cellRef}
        style={{ position: "relative" }}
        onMouseEnter={() => setShowTooltip(true)}
        onMouseLeave={() => setShowTooltip(false)}
      >
        <div
          style={{
            position: "sticky",
            left: "100px",
            zIndex: 1,
            minWidth: "200px",
            maxWidth: "200px",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {item.product.title}
        </div>
        {showTooltip && (
          <Tooltip
            content={prepInstructionsTable}
            currentRect={cellRef.current.getBoundingClientRect()}
            position="right"
          />
        )}
      </td>
      <td
        style={{
          position: "sticky",
          left: "300px",
          backgroundColor: "white",
          zIndex: 1,
          minWidth: "100px",
        }}
      >
        {item.quantity_shipped}
      </td>
      {billingFields.map((field) => (
        <BillingCell
          key={field.billingColumn}
          item={item}
          field={field}
          billingProfile={billingProfile}
          billingData={billingData}
          onInputChange={onInputChange}
          showCosts={showCosts}
        />
      ))}
    </tr>
  );
};

export default BillingRow;
